var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            [
              _c(
                "v-row",
                {
                  attrs: {
                    align: "center",
                    justify: "space-between",
                    "no-gutters": "",
                  },
                },
                [
                  _c("v-col", { attrs: { cols: "12", md: "4" } }, [
                    _c("span", { staticClass: "headline" }, [
                      _vm._v(_vm._s(_vm.$t("statics.title.list"))),
                    ]),
                  ]),
                  _c(
                    "v-col",
                    {
                      staticClass: "text-right",
                      attrs: { cols: "12", md: "8" },
                    },
                    [
                      _c("v-text-field", {
                        staticClass: "d-inline-block",
                        attrs: {
                          dense: "",
                          "append-icon": "search",
                          placeholder: _vm.$t("statics.search_placeholder"),
                          "single-line": "",
                          "hide-details": "",
                        },
                        model: {
                          value: _vm.search,
                          callback: function ($$v) {
                            _vm.search = $$v
                          },
                          expression: "search",
                        },
                      }),
                      _c(
                        "v-btn",
                        {
                          staticClass: "success ml-2",
                          attrs: { to: { name: "Static_PageCreate" } },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("new")) + " ")]
                      ),
                      _c(
                        "v-tooltip",
                        {
                          attrs: { bottom: "" },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function ({ on }) {
                                return [
                                  _c(
                                    "div",
                                    _vm._g(
                                      { staticStyle: { display: "inline" } },
                                      on
                                    ),
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "primary ml-2",
                                          attrs: {
                                            disabled:
                                              !_vm.hasFeatureStaticPages ||
                                              _vm.existsHomePage,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.generateHomePage()
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "statics.generateHomePage.button"
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ]),
                        },
                        [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.$t("statics.generateHomePage.information")
                              )
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-text",
            [
              _c("v-data-table", {
                attrs: {
                  headers: _vm.headers,
                  items: _vm.statics,
                  "item-key": "id",
                  search: _vm.search,
                  "custom-filter": _vm.filterIdAndHTML,
                },
                on: { "click:row": _vm.staticPageDetail },
                scopedSlots: _vm._u(
                  [
                    {
                      key: `item.action`,
                      fn: function ({ item }) {
                        return [
                          _c(
                            "v-icon",
                            {
                              staticClass: "mr-1",
                              attrs: { color: "blue" },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.showPreview(item)
                                },
                              },
                            },
                            [_vm._v(" filter_none ")]
                          ),
                          _c(
                            "v-icon",
                            {
                              attrs: { color: "green" },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.staticPageDetail(item)
                                },
                              },
                            },
                            [_vm._v(" visibility ")]
                          ),
                          _c(
                            "v-icon",
                            {
                              attrs: { color: "orange" },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.editStaticPage(item)
                                },
                              },
                            },
                            [_vm._v(" edit ")]
                          ),
                          _c(
                            "v-icon",
                            {
                              attrs: { color: "red" },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.showDeleteDialog(item)
                                },
                              },
                            },
                            [_vm._v(" delete ")]
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("modal-dialog", {
        attrs: {
          dialog: _vm.deleteDialog,
          title: _vm.$t("statics.delete_title"),
          titleClass: "error white--text",
          titleIcon: "warning",
          submitClass: "error",
          submitText: _vm.$t("delete"),
          content: _vm.$t("statics.delete_dialog"),
        },
        on: {
          cancel: function ($event) {
            _vm.deleteDialog = false
          },
          submit: function ($event) {
            return _vm.deleteStaticPage(_vm.selected.id)
          },
        },
      }),
      _c(
        "v-dialog",
        {
          model: {
            value: _vm.previewDialog,
            callback: function ($$v) {
              _vm.previewDialog = $$v
            },
            expression: "previewDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                [
                  _c("span", [_vm._v(_vm._s(_vm.$t("statics.title.preview")))]),
                  _c("v-spacer"),
                  _c(
                    "v-icon",
                    {
                      on: {
                        click: function ($event) {
                          _vm.previewDialog = false
                        },
                      },
                    },
                    [_vm._v("close")]
                  ),
                ],
                1
              ),
              _c(
                "v-card-text",
                [_c("preview-code", { attrs: { body: _vm.preview_selected } })],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }