<template>
  <v-container>
    <v-card>
      <v-card-title>
        <v-row align="center" justify="space-between" no-gutters>
          <v-col cols="12" md="4">
            <span class="headline">{{ $t("statics.title.list") }}</span>
          </v-col>
          <v-col cols="12" md="8" class="text-right">
            <v-text-field
              dense
              v-model="search"
              append-icon="search"
              :placeholder="$t('statics.search_placeholder')"
              single-line
              hide-details
              class="d-inline-block"
            ></v-text-field>

            <v-btn class="success ml-2" :to="{ name: 'Static_PageCreate' }">
              {{ $t("new") }}
            </v-btn>

            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <div style="display: inline" v-on="on">
                  <v-btn
                    @click="generateHomePage()"
                    class="primary ml-2"
                    :disabled="!hasFeatureStaticPages || existsHomePage"
                  >
                    {{ $t("statics.generateHomePage.button") }}
                  </v-btn>
                </div>
              </template>
              <span>{{ $t("statics.generateHomePage.information") }}</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-card-title>

      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="statics"
          item-key="id"
          :search="search"
          :custom-filter="filterIdAndHTML"
          @click:row="staticPageDetail"
        >
          <template v-slot:[`item.action`]="{ item }">
            <v-icon class="mr-1" color="blue" @click.stop="showPreview(item)">
              filter_none
            </v-icon>
            <v-icon color="green" @click.stop="staticPageDetail(item)">
              visibility
            </v-icon>
            <v-icon color="orange" @click.stop="editStaticPage(item)">
              edit
            </v-icon>
            <v-icon color="red" @click.stop="showDeleteDialog(item)">
              delete
            </v-icon>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <!-- Dialogo para la eliminacion de paginas estaticas -->
    <modal-dialog
      @cancel="deleteDialog = false"
      @submit="deleteStaticPage(selected.id)"
      :dialog="deleteDialog"
      :title="$t('statics.delete_title')"
      titleClass="error white--text"
      titleIcon="warning"
      submitClass="error"
      :submitText="$t('delete')"
      :content="$t('statics.delete_dialog')"
    ></modal-dialog>

    <!-- Dialogo que muestra el preview de las paginas estaticas -->
    <v-dialog v-model="previewDialog">
      <v-card>
        <v-card-title>
          <span>{{ $t("statics.title.preview") }}</span>
          <v-spacer></v-spacer>
          <v-icon @click="previewDialog = false">close</v-icon>
        </v-card-title>
        <v-card-text>
          <preview-code :body="preview_selected"></preview-code>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import engineStore from "@/services/engineStore";
import ModalDialog from "@/components/common/ModalDialog";
import PreviewCodeVue from "./PreviewCode.vue";

export default {
  name: "Static_PageList",
  components: { "preview-code": PreviewCodeVue, ModalDialog },
  data() {
    return {
      search: "",
      headers: [
        { text: this.$t("statics.pageId"), value: "id" },
        {
          text: "",
          sortable: false,
          value: "action",
        },
      ],
      selected: null,
      statics: [],
      preview_selected: null,
      previewDialog: false,
      deleteDialog: false,
    };
  },
  computed: {
    hasFeatureStaticPages() {
      return engineStore.selection.indexOf("GUI_StaticPages") != -1;
    },
    existsHomePage() {
      return engineStore.statics.find((s) => s.id == "Home") != undefined;
    },
  },
  created() {
    this.getStatics();
  },
  methods: {
    getStatics() {
      this.statics = engineStore.statics;
    },
    showPreview(selection) {
      this.previewDialog = true;
      this.preview_selected = selection.html;
    },
    staticPageDetail(item) {
      this.$router
        .push({
          name: "Static_PageDetail",
          params: { id: item.id },
        })
        .catch(() => {}); // To avoid 'Uncaught in promise' error in console
    },
    editStaticPage(item) {
      this.$router.push({
        name: "Static_PageForm",
        params: { id: item.id },
      });
    },
    showDeleteDialog(item) {
      this.selected = item;
      this.deleteDialog = true;
    },
    deleteStaticPage(id) {
      engineStore.statics.forEach(function (item, index, object) {
        if (item.id == id) {
          object.splice(index, 1);
        }
      });
      this.getStatics();
      this.selected = null;
      this.deleteDialog = false;
    },
    filterIdAndHTML: function (value, search, item) {
      var static_page = this.statics.filter((el) => el.id === item.id);
      return (
        value != null &&
        search != null &&
        (static_page[0].id.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
          static_page[0].html.toLowerCase().indexOf(search.toLowerCase()) !==
            -1)
      );
    },
    generateHomePage() {
      const home = {
        id: "Home",
        html:
          '<h1 class="ql-align-center">Welcome to ' +
          engineStore.basicData.name +
          "</h1>",
      };
      engineStore.addStaticPage(home);
      this.editStaticPage(home);
    },
  },
};
</script>
